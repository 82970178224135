import React from "react";
import whatsapp from "../Assets/Img/whatsapp.png"


function Contact() {
  return (
    <>
      <div class="container-xxl py-5">
        <div class="container">
          <h6 class="section-title bg-white text-start text-primary pe-3">
            Contact Us
          </h6>
          <a href="https://wa.me/+919996594041" style={{position:'fixed',top:'80vh',right:'30px'}}><img style={{width:'50px'}} src={whatsapp} alt="whatsapp" srcset="" /></a>
        </div>
      </div>
    </>
  );
}

export default Contact;
