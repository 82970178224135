import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";

var firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyB1blFfv2MsTX3zYcHPl_WAOODGPULY_nQ",
  authDomain: "techrysen-47771.firebaseapp.com",
  projectId: "techrysen-47771",
  storageBucket: "techrysen-47771.appspot.com",
  messagingSenderId: "425164635691",
  appId: "1:425164635691:web:5b9cf283ae11736a25b46e",
  measurementId: "G-8BKYH6068H",
});

// Initialize Firebase
const db = firebaseApp.firestore();
export const auth = getAuth(firebaseApp);
export const database = getDatabase(firebaseApp);
const storage = getStorage(firebaseApp);

export { db, storage };
