import React, { useState } from "react";
import about from "../Assets/Img/about.jpg";
import crmdev from "../Assets/Img/crmdev.jpg";
import ecomdev from "../Assets/Img/ecommercedev.jpg";
import mobileappdev from "../Assets/Img/mobileapp.jpg";
import webdev from "../Assets/Img/webdev.jpg";
import facetimedev from "../Assets/Img/5064.jpg";
import seodev from "../Assets/Img/138498.jpg";
import graphicdesign from "../Assets/Img/graphicdesign.jpg";
import contact from "../Assets/Img/contact.jpg";

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { db } from "../config/firebase";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import whatsapp from "../Assets/Img/whatsapp.png"

function Services(){
return (
    <>
 <div class="container-xxl py-5">
        <div class="container">
          <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 class="section-title bg-white text-center text-primary px-3">
              Services
            </h6>
            <h1 class="mb-5">Our Services</h1>
          </div>
          <div class="row g-4">
            <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
              <div class="service-item rounded">
                <img
                  style={{ width: "100%" }}
                  src={crmdev}
                  alt="crm development"
                />
                <div class="p-4">
                  {/* <i class="fa fa-3x fa-globe text-primary mb-4"></i> */}
                  <h5>CRM Development</h5>
                  <p>
                    Creating tailored CRM systems to streamline customer
                    interactions, improve data management, and enhance business
                    efficiency and growth.
                  </p>

                  <a href="/">
                    Learn More{" "}
                    <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
              <div class="service-item rounded">
                <img
                  style={{ width: "100%" }}
                  src={ecomdev}
                  alt="Ecommerce Development"
                />
                <div class="p-4">
                  <h5>E-Commerce Development</h5>
                  <p>
                    Developing user-friendly and secure eCommerce websites with
                    seamless navigation, integrated payment systems, and
                    optimized performance to boost online sales.
                  </p>
                  <a href="/">
                    Learn More{" "}
                    <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
              <div class="service-item rounded">
                <img
                  style={{ width: "100%" }}
                  src={webdev}
                  alt="Website Design and Development"
                />
                <div class="p-4">
                  <h5>Website Design & Development</h5>
                  <p>
                    We craft high-performance websites that are visually
                    stunning, user-friendly, and optimized for conversion.
                  </p>
                  <a href="/">
                    Learn More{" "}
                    <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
              <div class="service-item rounded">
                <img
                  style={{ width: "100%" }}
                  src={mobileappdev}
                  alt="Mobile App Development"
                />
                <div class="p-4">
                  <h5>Mobile Application Development</h5>
                  <p>
                    Creating high-performance mobile apps with intuitive design
                    and tailored functionality for your needs.
                  </p>
                  <a href="/">
                    Learn More{" "}
                    <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
              <div class="service-item rounded">
                <img
                  style={{ width: "100%" }}
                  src={facetimedev}
                  alt="Face Scan Attendance Software"
                />
                <div class="p-4">
                  <h5>Face Scan Attendance Software</h5>
                  <p>
                    Efficient attendance management using advanced facial
                    recognition for accurate, secure workforce tracking.
                  </p>
                  <a href="/">
                    Learn More{" "}
                    <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
              <div class="service-item rounded">
                <img
                  style={{ width: "100%" }}
                  src={seodev}
                  alt="Search Engine Optimization"
                />
                <div class="p-4">
                  <h5>Search Engine Optimization</h5>
                  <p>
                    We employ a strategic SEO approach to propel you to the top
                    of search engine results pages and drive organic traffic.
                  </p>
                  <a href="/">
                    Learn More{" "}
                    <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
              <div class="service-item rounded">
                <img
                  style={{ width: "100%" }}
                  src={graphicdesign}
                  alt="graphic design"
                />
                <div class="p-4">
                  <h5>Graphic Designing</h5>
                  <p>
                    Transforming ideas into visually compelling graphics, from
                    logos and branding to marketing materials, with creative and
                    professional design solutions.
                  </p>
                  <a href="/">
                    Learn More{" "}
                    <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <a href="https://wa.me/+919996594041" style={{position:'fixed',top:'80vh',right:'30px'}}><img style={{width:'50px'}} src={whatsapp} alt="whatsapp" srcset="" /></a>
      </div>
    </>
  );
}
export default Services;
