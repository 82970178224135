import React, { useState } from "react";
import about from "../Assets/Img/about.jpg";
import crmdev from "../Assets/Img/crmdev.jpg";
import ecomdev from "../Assets/Img/ecommercedev.jpg";
import mobileappdev from "../Assets/Img/mobileapp.jpg";
import webdev from "../Assets/Img/webdev.jpg";
import facetimedev from "../Assets/Img/5064.jpg";
import seodev from "../Assets/Img/138498.jpg";
import graphicdesign from "../Assets/Img/graphicdesign.jpg";
import contact from "../Assets/Img/contact.jpg";

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { db } from "../config/firebase";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import whatsapp from "../Assets/Img/whatsapp.png"

function About(){
return (
    <>
  <div class="container-xxl py-5">
        <div class="container">
          <div class="row g-5">
            <div
              class="col-lg-6 wow fadeInUp"
              data-wow-delay="0.1s"
              style={{ minHeight: "400px" }}
            >
              <div class="position-relative h-100">
                <img
                  class="img-fluid position-absolute w-100 h-100"
                  src={about}
                  alt="TechIndiaPioneer Your Partner in Digital Growth"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
            <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <h6 class="section-title bg-white text-start text-primary pe-3">
                About Us
              </h6>
              <h1 class="mb-4">
                Welcome to <span class="text-primary">TechIndiaPioneer</span>
              </h1>
              <p class="mb-4">
                The TechIndiaPioneer excels with unmatched expertise, innovative
                solutions, and a client-centric approach, delivering top-tier
                web and app development tailored to unique business needs.
              </p>
              <p class="mb-4">
                Established with a vision to revolutionize the digital
                landscape, we specialize in providing top-tier tech services,
                including CRM Development, Web Design & Development, E-Commerce
                Development, Mobile app development, and Graphic Design. Our
                mission is to empower businesses by transforming their digital
                presence and driving their success through cutting-edge
                technology and creative solutions.
              </p>

              <div class="row gy-2 gx-4 mb-4">
                <div class="col-sm-6">
                  <p class="mb-0">
                    <i class="fa fa-arrow-right text-primary me-2"></i>CRM
                    Development
                  </p>
                </div>
                <div class="col-sm-6">
                  <p class="mb-0">
                    <i class="fa fa-arrow-right text-primary me-2"></i>
                    Web Design & Development
                  </p>
                </div>
                <div class="col-sm-6">
                  <p class="mb-0">
                    <i class="fa fa-arrow-right text-primary me-2"></i>
                    E-Commerce Development
                  </p>
                </div>
                <div class="col-sm-6">
                  <p class="mb-0">
                    <i class="fa fa-arrow-right text-primary me-2"></i>Face Scan
                    Attendance System
                  </p>
                </div>
                <div class="col-sm-6">
                  <p class="mb-0">
                    <i class="fa fa-arrow-right text-primary me-2"></i>Mobile
                    App Development
                  </p>
                </div>
                <div class="col-sm-6">
                  <p class="mb-0">
                    <i class="fa fa-arrow-right text-primary me-2"></i>Search
                    Engine Optimization
                  </p>
                </div>

                {/* <div class="col-sm-6">
                  <p class="mb-0">
                    <i class="fa fa-arrow-right text-primary me-2"></i>24/7
                    Service
                  </p>
                </div> */}
              </div>
              <a class="btn btn-primary py-3 px-5 mt-2" href="">
                Read More
              </a>
            </div>
          </div>
          <a href="https://wa.me/+919996594041" style={{position:'fixed',top:'80vh',right:'30px'}}><img style={{width:'50px'}} src={whatsapp} alt="whatsapp" srcset="" /></a>
        </div>
      </div>
    </>
  );
}
export default About;
