import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import Footer from "./Components/Footer";
import Home from "./Components/Home";
import Navbar from "./Components/Navbar";
import Contact from "./Components/Contact";
import Services from "./Components/services";
import About from "./Components/About";

const oldUrls = [
  "/web-development-services",
  "/tech-india-pioneer",
  "/web-development",
  "/web-design",
  "/web-designer",
  "/web-developer",
  "/custom-websites",
  "/professional-web-development",
  "/professional-web-developer",
  "/crm-development",
  "/crm-developer",
  "/cheap-crm-developer",
  "/cheapest-crm-development",
  "/best-crm-development",
  "/business-websites",
  "/personal-websites",
  "/cheap-website-development",
  "/low-price-web-development",
  "/e-commerce-website",
  "/tech-india-pioneer-services",
  "/best-website-development-service",
  "/popular-development-service",
  "/best-mobile-app-development",
  "/cheap-mobile-app-development",
  "/low-budget-app-development",
  "/best-ecommerce-development-service",
  "/app-development-service",
  "/amazing-websites",
  "/android-developer",
  "/ios-developer",
  "/ios-development-company",
  "/android-development-company",
  "/app-development-company",
  "/mobile-app-development-company",
];

function App() {
  return (
    <div className="App">
      <Navbar />

      <Routes>
        {oldUrls.map((url, index) => (
          <Route key={index} path={url} element={<Navigate to="/" />} />
        ))}
        <Route path="/" element={<Home />} />

        <Route path="/contact-us-tech-rysen" element={<Contact />} />
        <Route path="/services" element={<Services />} />
        <Route path="/about" element={<About />} />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
